.InviteMember {
  @import 'src/styles/theme/main';

  column-gap: var(--space-16);
  row-gap: var(--space-24);
  flex-wrap: wrap;

  &__InputArea {
    flex-basis: 50%;

    &__BorderWrap {
      border: 1px solid var(--color-gray-default);
      border-radius: 4px;

      align-items: center;
      column-gap: 8px;
      padding: var(--space-8) var(--space-12) var(--space-10)
    }

    @include media-md-down {
      flex-basis: 100%;
    }
  }

  &__AccessLvl {
    flex-basis: 45%;

    &__Title {
      margin-bottom: var(--space-4);
      line-height: 1.4;
    }

    button {
      text-transform: none;
      font-weight: 400;
      color: var(--color-secondary-charcoal);
    }

    button[aria-selected='true'] {
      background: #f0fbf5 !important;
      border-top-color: var(--color-primary);
      border-bottom-color: var(--color-primary);
    }

    button:hover {
      background-color: var(--color-gray-default);
    }
  }

  @include media-md-down {
    &:not(:last-child) {
      padding-bottom: var(--space-20);
      border-bottom: 1px solid #aaa;
    }
  }
}
