.CompanySizeStep {
  --card-content-font-size: var(--font-size-16);
  --card-content-padding: var(--space-20) var(--space-8);
  --card-content-pb: var(--space-20);

  max-width: 450px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--space-20);

  & > * {
    flex-basis: 126px;
  }
}
