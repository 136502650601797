.FocusAreaStep {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  // Allow the bow-shadow to be visible
  padding: 0 0 2px;

  & > * {
    flex: 1 0 270px;
    max-width: 330px;
  }
}
