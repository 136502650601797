.DownloadAppsStep {
  @import 'src/styles/theme/main';
  padding-bottom: 20px;
  --card-content-padding: var(--space-20) var(--space-16);
  --card-content-pb: var(--space-20);

  column-gap: var(--space-20);
  row-gap: var(--space-20);

  @include media-sm-down {
    flex-wrap: wrap;

    & > div {
      width: 100%;
      height: auto;
    }
  }

  &__CardTitle {
    text-align: left;
  }

}

.basicCard {
  width: 100%;
  height: 100%;
  box-shadow: 0 12px 32px 0 #0F0F0F1F !important;
  cursor: default !important;
  &:hover {
    background-color: #fff !important;
    box-shadow: none;
  }
}

.basicCardHeader {
& img {
  margin: 0 auto
}
  & h5  {
    text-align: center;
    padding-top: 30px;
    font-size: 16px;
    font-weight: 700;
  }
}

.basicCardContent {
  align-items: flex-start;
  @import 'src/styles/theme/main';

  min-height: 200px;
  @include media-sm-down {
    min-height: 160px;
  }
  & ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0;
    list-style: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.44px;

    li {
      display: flex;
      align-items: flex-start;

      span {
        padding-left: 10px;
      }
    }
  }
}

.basicCardFooter {
   button {
     padding: 8px 16px;
     cursor: pointer;
     font-size: 14px;
     font-weight: 500;
     line-height: 20px;
     letter-spacing: 0.75px;
     border-radius: 4px;
     box-shadow: 0 1px 5px 0 #00000033;
   }
}
