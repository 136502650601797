.ComputersStep {
  @import 'src/styles/theme/main';

  --card-content-padding: var(--space-20) var(--space-16);
  --card-content-pb: var(--space-20);

  column-gap: var(--space-20);
  row-gap: var(--space-20);

  @include media-sm-down {
    flex-wrap: wrap;

    & > div {
      width: 100%;
    }
  }

  &__CardTitle {
    text-align: left;
  }
}
